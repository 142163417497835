.home-topic {
  background-image: url('../../../public/images/jlcontrol-office.jpg');
  background-size: cover;
  text-align: center;
  /* opacity: 0.3; */
}

.home-topic img {
  width: 40%;
}

.home-topic h5 {
  font-weight: 400;
  font-size: 1.35rem;
}

.home-all-head {
  font-size: 2rem;
  font-weight: 600;
  text-decoration: none;
}

.home-card-head {
  font-weight: 400;
}

.home-card-img {
  width: 85%;
}

.home-card-p {
  font-size: 1.2rem;
}

@media screen and (max-width: 1200px) {
  .home-topic img {
    width: 50%;
  }
  .home-card-head {
    font-size: 1.2rem;
  }
  .home-card-img {
    width: 100%;
  }
  .home-card-p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 992px) {
  .home-topic img {
    width: 60%;
  }
  .home-card-img {
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .home-topic img {
    width: 70%;
  }
  .home-card-img {
    width: 100%;
  }
}

.home-contact-img {
  width: 100%;
  border-radius: 10px;
}

.home-contact-button {
  border-radius: 100px;
  font-size: 1.2rem;
}

.home-contact-button i {
  font-size: 1.1rem;
}

.home-bottom-card {
  font-weight: 600;
  text-align: center;
}

.home-bottom-card p {
  color: #ffffff;
}
