.under-construction img {
  width: 100%;
}

.under-construction .head {
  font-size: 3rem;
  font-weight: 500;
}

.under-construction .announce {
  font-size: 1.5rem;
  font-weight: 400;
}
