.contact-map {
  object-fit: cover;
  object-position: 0 40%;
  width: 100%;
  height: 400px;
  border-radius: 30px;
}

.contact-detail {
  display: inline-flex;
}

@media screen and (max-width: 576px) {
  .contact-map {
    height: 200px;
  }
}

@media screen and (min-width: 576px) {
  .contact-map {
    height: 250px;
  }
}

@media screen and (min-width: 768px) {
  .contact-map {
    margin-top: 2%;
    height: 300px;
  }
}

@media screen and (min-width: 992px) {
  .contact-map {
    margin-top: 2%;
    height: 350px;
  }
}
@media screen and (min-width: 1200px) {
  .contact-map {
    margin-top: 2%;
    height: 400px;
  }
}
