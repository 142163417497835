.about-us-header {
  background-color: #ececec;
  text-align: center;
}

.about-us-information {
  text-align: center;
}

.about-us-sub-topic {
  font-size: 1.5rem;
  font-weight: 600;
}

.about-us-introduction {
  font-weight: 500;
}

.about-us-link {
  font-size: 1.5rem;
  text-decoration: none;
}
