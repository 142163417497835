@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css');

* {
  font-family: 'IBM Plex Sans Thai', sans-serif;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.color-line {
  height: 3px;
  width: 100%;
  border-radius: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h1 {
  font-size: 1.5rem;
  font-weight: 600;
}

h4 {
  font-weight: 500;
}

p {
  font-size: 1.2rem;
}

.jl-card {
  box-shadow: 0px 0px 10px 3px #e9e9e9;
  border-radius: 15px;
  background-color: #ffffff;
  padding: 1rem;
}

.color-blue {
  color: #3366ff;
}

.color-light-gray {
  color: #8c8c8c;
}
.color-gray {
  color: #595959;
}

.color-black {
  color: #000000;
}

.color-line-blue-green {
  background: linear-gradient(
    90deg,
    rgba(51, 102, 255, 1) 0%,
    rgba(0, 255, 194, 1) 100%
  );
  margin-top: 0.5rem !important;
}

.bottom-card-color-green-blue {
  background: linear-gradient(
    90deg,
    rgba(0, 255, 194, 1) 0%,
    rgba(51, 102, 255, 1) 100%
  );
}

.bottom-card-color-blue-sky {
  background: linear-gradient(
    90deg,
    rgba(51, 102, 255, 1) 0%,
    rgba(51, 243, 255, 1) 100%
  );
}

.color-line-purple-blue {
  background: linear-gradient(
    90deg,
    rgba(219, 0, 255, 1) 0%,
    rgba(51, 102, 255, 1) 100%
  );
  margin-top: 0.5rem !important;
}

.color-line-sky-blue-sky {
  background: linear-gradient(
    90deg,
    rgba(51, 243, 255, 1) 0%,
    rgba(51, 102, 255, 1) 50%,
    rgba(51, 243, 255, 1) 100%
  );
  margin-top: 0.5rem !important;
}

.color-line-purple-blue-purple {
  background: linear-gradient(
    90deg,
    rgba(153, 36, 235, 1) 0%,
    rgba(51, 102, 255, 1) 50%,
    rgba(153, 36, 235, 1) 100%
  );
}
