.sofware-dev-cover {
  width: 90%;
}

.sofware-dev-card-topic {
  font-size: 1.4rem;
  font-weight: 500;
}

.software-dev-card-detail-year {
  font-size: 1.1rem;
  font-weight: 500;
}

.software-dev-card-detail-description {
  font-size: 1.1rem;
  font-weight: 300;
}

.software-dev-card-detail-tool {
  font-size: 1.1rem;
}

.software-dev-card-button {
  width: 100%;
  border-radius: 100px;
  font-size: 1.3rem;
}

.software-dev-card-button i {
  font-size: 1rem;
}

.software-dev-card-button p {
  font-size: 1rem;
}

.software-dev-modal-body p {
  margin-bottom: 0.5rem;
}

.software-dev-modal-body b {
  font-weight: 500;
}

.software-dev-modal-body li {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}
