.navigation-bar-menu {
  font-weight: 400;
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  cursor: pointer;
}

.navigation-bar-menu:hover {
  transform: scale(1.1);
}

.footer {
  box-shadow: 0px 0px 10px 3px #d4d4d4;
}

.footer a {
  text-decoration: none;
}

.footer-detail {
  font-weight: 300;
  font-size: 1rem;
}

.main-section {
  min-height: 70vh;
}
